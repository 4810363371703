import React, { useContext, useEffect, useState } from 'react'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'
import IRow from '../types/IRow'
import api from '../../../../../services/api'
import { Flex } from '@chakra-ui/react'
import IFilters from 'pages/PriceBaseTable/types/IFilters'
import Filters from '../Filters'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import CheckboxTaxRecovery from './CheckboxTaxRecovery'
import Percent from './Percent'
import Input from './Input'
import { Delete } from 'pages/UsersEdit/Inputs/Access/Cluster/styles'
import { UserContext } from 'state/user-context'
import ModalUploadCompetitor from '../ModalUploadCompetitor'
import ModalAddCompetitor from '../ModalAddCompetitor'
import ExportExcelButton from '../ExportExcelButton'
import DeleteModal from '../DeleteModal'
import { Checkbox } from '@chakra-ui/react'
import Button from 'components/Button'

interface IProps extends IPaginationOptions {
  data: IRow[]
  loading: boolean
  clusterId: string
  binaryPos: number[]
  filters: any
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  total: number
  page: number
  limit: number
  setLimit: (number: number) => void
}

const Table: React.FC<IProps> = ({
  data,
  loading,
  clusterId,
  setPage,
  setFilters,
  filters,
  setLoading,
  total,
  page,
  limit,
  setLimit,
}) => {
  const [rowsState, setRowsState] = useState<any[]>([])
  const [order, setOrder] = useState(1)
  const [rowToDelete, setRowToDelete] = useState<any>('')
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [allSelected, setAllSelected] = useState(false)
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [type, setType] = useState('')
  const [idsToNotExclude, setIdsToNotExclude] = useState<any[]>([])
  const [newTotal, setNewTotal] = useState(total)

  useEffect(() => {
    setRowsState(data)
    setNewTotal(total)
  }, [data, total])

  const { userParameters } = useContext(UserContext)

  const deleteRow = (id: number) => {
    api
      .delete(`cluster-competitor/delete?id=${id}`)
      .then((res) => {
        setRowsState(res.data.data)
        setOpenDeleteModal(false)
        setNewTotal(res.data.data.length)
      })
      .catch((error) => {
        console.error('Failed to delete competitor:', error)
        // You can add an error handling mechanism, like displaying a toast.
      })
  }

  const handleHeaderCheckboxChange = () => {
    setAllSelected(!allSelected)
  }

  const handleNewChangeCheckBox = (e: any, rowData: any) => {
    if (allSelected) {
      const newData = [...idsToNotExclude]
      const exists = newData.some((row) => row.id === rowData.id)

      if (exists) {
        const filteredData = newData.filter((row) => row.id !== rowData.id)
        setIdsToNotExclude(filteredData)
      } else {
        newData.push(rowData)
        setIdsToNotExclude(newData)
      }
    } else {
      const newData = [...selectedRows]
      const exists = newData.some((row) => row.id === rowData.id)

      if (exists) {
        const filteredData = newData.filter((row) => row.id !== rowData.id)
        setSelectedRows(filteredData)
      } else {
        newData.push(rowData)
        setSelectedRows(newData)
      }
    }
  }

  const handleDeleteSelected = () => {
    if (allSelected) {
      const ids = idsToNotExclude.map((item) => Number(item.id))
      api
        .post(`cluster-competitor/delete-all`, ids)
        .then((res) => {
          setRowsState(res.data.data)
          setOpenDeleteModal(false)
          setIdsToNotExclude([])
          setAllSelected(false)
          setNewTotal(res.data.data.length)
        })
        .catch((error) => {
          console.error('Failed to delete competitor:', error)
        })
    } else {
      const idsToExclude = selectedRows.map((item) => Number(item.id))
      api
        .post(`cluster-competitor/delete-selected`, idsToExclude)
        .then((res) => {
          setRowsState(res.data.data)
          setOpenDeleteModal(false)
          setNewTotal(res.data.data.length)
        })
        .catch((error) => {
          console.error('Failed to delete competitor:', error)
        })
    }
  }

  const handleConfirmDeleteSelected = () => {
    setOpenDeleteModal(true)
    setType('selected')
  }

  const updateRow = () => {}

  const clients = rowsState.map((item: any) => {
    return {
      label: item.client_name,
      value: item.client_code,
    }
  })

  const uniqueChannelsMap = new Map()

  data.forEach((item: any) => {
    const uniqueKey = `${item.channel_code}_${item.channel_name}`
    if (!uniqueChannelsMap.has(uniqueKey)) {
      uniqueChannelsMap.set(uniqueKey, {
        label: item.channel_name,
        value: item.channel_code,
      })
    }
  })

  const channels = Array.from(uniqueChannelsMap.values())

  const checkboxTemplate = (rowData: any, type: string, updateRow: any) => {
    return (
      <CheckboxTaxRecovery
        param={type}
        row={rowData}
        updateCell={updateRow}
        // toggleBigData={toggleBigData}
      />
    )
  }

  const historicTemplate = (rowData: any, type: string) => {
    return <Percent value={rowData[type]} />
  }

  const inputTemplate = (rowData: any, type: string, updateRow: any) => {
    return <Input row={rowData} param={type} onUpdate={updateRow} />
  }

  const deleteTemplate = (rowData: any) => {
    if (userParameters.write) {
      return (
        <Delete
          onClick={() => {
            setRowToDelete(rowData)
            setOpenDeleteModal(true)
            setType('one')
          }}
        />
      )
    } else return <></>
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setRowsState((prevData: IRow[]) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  if (!clusterId) return null
  if (loading) return <LoadingScreen />
  return (
    <Paper containerStyle={{ width: '100%' }}>
      <DeleteModal
        isOpen={openDeleteModal}
        row={rowToDelete}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={() => deleteRow(rowToDelete.id)}
        type={type}
        onDeleteSelected={handleDeleteSelected}
      />
      <Flex marginBottom={5} justify="right">
        <div style={{ gap: 20, display: 'flex', flexDirection: 'row' }}>
          <ModalAddCompetitor
            clients={clients}
            channels={channels}
            setNewRows={setRowsState}
          />
          <ExportExcelButton filter={filters} />
          <ModalUploadCompetitor
            clients={clients}
            channels={channels}
            setNewRows={setRowsState}
          />
          <Filters
            setPage={setPage}
            setLoading={setLoading}
            filters={filters}
            setFilters={setFilters}
          />
          <Button
            style={{
              display: selectedRows.length > 0 || allSelected ? 'flex' : 'none',
              backgroundColor: 'brown',
            }}
            onClick={handleConfirmDeleteSelected}
          >
            Excluir Selecionados
          </Button>
        </div>

        {/* <div>
          <Button onClick={handleSave}>Salvar </Button>
        </div> */}
      </Flex>
      <DataTable
        key={data.length}
        onSort={onSort}
        scrollable
        scrollHeight="flex"
        paginator
        rows={15}
        lazy
        onPage={onPage}
        first={page * limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={rowsState}
        totalRecords={newTotal}
        // selectionMode="multiple"
        // selection={selectedRows}
        // onSelectionChange={onSelectionChange}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate={`Exibindo de {first} à {last} de ${newTotal} registros`}
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          frozen
          header={
            <Checkbox
              style={{ borderColor: 'black' }}
              size="lg"
              isChecked={allSelected}
              onChange={handleHeaderCheckboxChange}
            />
          }
          body={(rowData) => (
            <Checkbox
              style={{ borderColor: 'black' }}
              size="lg"
              isChecked={
                selectedRows.some((row: any) => row.id === rowData.id) ||
                (allSelected &&
                  !idsToNotExclude.some((row: any) => row.id === rowData.id))
              }
              onChange={(e) => handleNewChangeCheckBox(e, rowData)}
            />
          )}
          style={{ width: '3em' }}
        />
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="cluster_name"
          header="Cluster"
          style={{ minWidth: '70px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="uf"
          header="UF"
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="channel_name"
          header="Canal"
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="sku"
          header="SKU Produto"
          style={{ minWidth: '80px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="sku_description"
          header="Descrição"
          style={{ minWidth: '200px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="ean_competitor"
          header="EAN Concorrente"
          style={{ minWidth: '110px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="competitor_description"
          header="Descrição Concorrente"
          style={{ minWidth: '200px', zIndex: '2' }}
        ></Column>

        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="description_priority"
          header="Prioridade"
          style={{ minWidth: '70px', zIndex: '2' }}
        ></Column>

        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header=""
          body={(rowData) => deleteTemplate(rowData)}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
      </DataTable>
    </Paper>
  )
}

export default Table
