import React, {
  KeyboardEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react'
import { useToast } from '@chakra-ui/react'
import api from 'services/api'
import Indicator from './Indicator'
import { UserContext } from 'state/user-context'
import { InputText } from 'primereact/inputtext'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './styles.css'

interface IProps {
  row: { [key: string]: any }
  rowIndex: number
  inputsRef: React.MutableRefObject<(HTMLInputElement | null)[]>
  updateRow: (newRow: { [key: string]: any }) => void
  toggleBigData: () => void
  getRows: () => void
  type: string
}

export const PPVFinal: React.FC<IProps> = ({
  row,
  rowIndex,
  inputsRef,
  updateRow,
  toggleBigData,
  getRows,
  type,
}) => {
  const toast = useToast()
  const { userScenery } = useContext(UserContext)
  const formatValue = (value: any) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
    })
      ?.format(parseFloat(value?.replace(',', '.')))
      ?.replace('.', ',')

  const [value, setValue] = useState(
    !row.preco_bruto_sugestao || isNaN(row.preco_bruto_sugestao)
      ? '0,00'
      : formatValue(row.preco_bruto_sugestao)
  )
  const [focusValue, setFocusValue] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setValue(row.preco_bruto_sugestao)
  }, [row.preco_bruto_sugestao])

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const borderColor = useMemo(
    () =>
      `${
        row.preco_bruto_sugestao !== row.ppc_s_mrg ? '#BEE3F8' : '#E2E8F0'
      } !important`,
    [row.ppc_s_mrg, row.preco_bruto_sugestao]
  )

  const onBlur = () => {
    if (!Number(value.replace('.', '').replace(',', '.'))) {
      return setValue(focusValue)
    }

    const formattedValue = formatValue(value)
    if (formattedValue === formatValue(row.preco_bruto_sugestao)) return

    if (row) {
      const url = `/sceneries/${row.scenery_id}/edit`
      const req = {
        id: String(row?.id)?.includes('-') ? row?.newId : row?.id,
        preco_bruto_sugestao: formattedValue.replace(',', '.'),
        status: 'S',
        type: type !== 'trading_condition' ? 'scenery' : 'trading_condition',
      }

      api
        .put(url, req)
        .then((res) => {
          const formattedRow = res.data.data[0]
          updateRow({
            ...row,
            ...formattedRow,
            preco_bruto_sugestao: formattedValue,
            status: 'S',
          })
          setValue(formattedValue)
          toggleBigData()
          toast({
            status: 'success',
            title: 'Preço bruto sugestão atualizado com sucesso',
          })
        })
        .catch(() => {
          toast({
            status: 'error',
            title: 'Erro, tente novamente.',
          })
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.target.value.replace(',', '.'))

    setValue(e.target.value)
  }, [])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const nextInput = inputsRef.current[rowIndex + 1]
        if (nextInput) {
          nextInput.focus()
        } else {
          e.currentTarget.blur()
        }
      }
    },
    [inputsRef, rowIndex]
  )

  useEffect(() => {
    inputsRef.current[rowIndex] = inputRef.current
  }, [rowIndex, inputsRef])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Indicator row={row} />
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">R$</span>
        <InputText
          ref={inputRef}
          disabled={!userScenery.write}
          style={{
            height: 32,
            borderColor: borderColor,
            textAlign: 'center',
            maxWidth: 80,
          }}
          value={value}
          onBlur={onBlur}
          placeholder={focusValue}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
        />
      </div>
    </div>
  )
}

export default PPVFinal
