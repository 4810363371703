import React, { useCallback, useState } from 'react'
import { FaTrash as DeleteSvg } from 'react-icons/fa'
import { useToast } from '@chakra-ui/react'
import api from 'services/api'
import { IDeleteComponent } from '../types'
import { IMaterials } from '../../types'
import DeleteModal from './DeleteModal'

export const DeleteComponent: React.FC<IDeleteComponent> = ({
  row,
  setRows,
}) => {
  const toast = useToast()
  const [rowToDelete, setRowToDelete] = useState<any>('')
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const onDelete = useCallback(
    (skuToDel: string) => {
      api
        .delete(`/relativity-sku/${skuToDel}`)
        .then(() => {
          toast({
            title: 'Excluido com sucesso',
            status: 'success',
            isClosable: true,
          })
          setRows((current: IMaterials[]) => {
            return current.filter((row: IMaterials) => row?.sku !== skuToDel)
          })
        })
        .catch(() => {
          toast({
            title: 'Houve um erro ao exluir',
            status: 'error',
            isClosable: true,
          })
        })
    },
    [setRows, toast]
  )

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        row={rowToDelete}
        onClose={() => setOpenDeleteModal(false)}
        // onDelete={() => onDelete(row.sku)}
      />
      {row.sku === row.captain_code ? (
        ''
      ) : (
        <DeleteSvg
          onClick={() => {
            setOpenDeleteModal(true)
            setRowToDelete(row)
          }}
        />
      )}
    </>
  )
}
