import { useCallback, useContext, useEffect, useState } from 'react'
import Table from './Table'
import { Paper } from '@material-ui/core'
import { UserContext } from 'state/user-context'
import { getClusterConcorrenteListService } from 'services/ClusterConcorrenteTable/cluster-concorrente-service'

const binaryPos = [1, 2, 4, 8, 16]

const ClusterConcorrente = () => {
  const { user } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState<any>({})
  const [limit, setLimit] = useState(15)

  // useEffect(() => {
  //   setPage(0)
  //   setLoading(true)
  // }, [filters])

  useEffect(() => {
    getRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page])

  useEffect(() => {
    if (
      user?.newClusters &&
      user?.newClusters?.length > 0 &&
      !user?.clusters?.includes(0)
    ) {
      setFilters({
        ...filters,
        cluster_id: user?.newClusters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const getRows = useCallback(async () => {
    setLoading(true)
    const request: any = {}
    const newFilters: any = {
      cluster_id: Number(filters.clusterSelected?.value),
      sku: filters.skuSelected?.value,
      description_priority: filters.prioritySelected,
      competitor_description: filters.competitorSelected?.value,
      channel_code: filters.channelsSelected?.value,
      uf: filters.ufSelected?.label,
    }

    const newRequest = {
      ...newFilters,
      cluster_id:
        request?.cluster_id?.length < 1 ? user?.clusters : request.cluster_id,
    }

    try {
      const result = await getClusterConcorrenteListService(
        newRequest,
        page === 0 ? 1 : page,
        limit
      )
      setRows(result.result)
      setTotal(result.total)
      setTotalPages(result?.data?.totalPages)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page])

  useEffect(() => {
    if (loading) getRows()
  }, [loading, getRows])

  return (
    <Paper>
      <Table
        data={rows}
        loading={loading}
        clusterId={'0'}
        binaryPos={binaryPos}
        page={page}
        limit={limit}
        total={Number(total)}
        setPage={setPage}
        setLimit={setLimit}
        totalPages={totalPages}
        filters={filters}
        setFilters={setFilters}
        setLoading={setLoading}
      />
    </Paper>
  )
}

export default ClusterConcorrente
